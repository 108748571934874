import React, { useEffect } from 'react'
import { useInView } from 'react-intersection-observer'
import { motion, useAnimation } from 'framer-motion'
import styled from 'styled-components'
import { ReUsePtag } from './ReUsePtag'
import { ReuseImgTag } from './ReuseImgTag'

const CustomersReviewBlockWapper = styled.div`
  h2 {
    /* color: rgb(15, 33, 55); */
    color: #047cc2;
  }
  p {
    /* color: rgba(52, 61, 72, 0.8); */
    color: #1f1f1f;
  }
`
export const CustomersReviewBlock = (props: any) => {
  const controls = useAnimation()
  const [ref, inView] = useInView({
    // Percentage of item in view to trigger animation
    threshold: 0.25,
  })

  useEffect(() => {
    if (inView) {
      controls.start('visible')
    }
  }, [controls, inView])
  return (
    <CustomersReviewBlockWapper>
      <motion.div
        className={props.mainContain}
        ref={ref}
        id={props.id}
        animate={controls}
        initial="hidden"
        variants={{
          visible: { opacity: 1, y: 0 },
          hidden: { opacity: 0, y: 25 },
        }}
        transition={{ ease: 'easeOut', duration: 1.25, delay: 0.35 }}
      >
        <div className={props.InternalSection}>
          <div className={props.containImage}>
            <ReuseImgTag ImagePath={props.ImagePath} AltName={props.altName} ImageStyle={props.allImage} />
            <ReUsePtag
              para={props.paraTwo}
              paraStyle={props.containParaTwo}
              Icon={props.IconTwo}
              IconStyle={props.IconColorTwo}
            />
            <ReUsePtag
              para={props.paraThree}
              paraStyle={props.containParaThree}
              Icon={props.IconThree}
              IconStyle={props.IconColorThree}
            />
          </div>
          <div className={props.paraSection}>
            <ReUsePtag
              para={props.paraOne}
              paraStyle={props.containParaOne}
              Icon={props.IconOne}
              IconStyle={props.IconColorOne}
            />
            <ReUsePtag
              para={props.paraFour}
              paraStyle={props.containParaFour}
              Icon={props.IconFour}
              IconStyle={props.IconColorFour}
            />
            <ReUsePtag
              para={props.paraFive}
              paraStyle={props.containParaFive}
              Icon={props.IconFive}
              IconStyle={props.IconColorFive}
            />
          </div>
        </div>
      </motion.div>
    </CustomersReviewBlockWapper>
  )
}
